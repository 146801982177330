@import "../colors";
@import "../variables";

.home {
  &-choice {
    width: 100%;
    padding-top: 10vh;
    @media (max-width: 1440px) {
        padding-top: 5vh;
    }
    padding-bottom: 40px;
    justify-content: center;
    display: flex;
    align-items: center;

    @media (max-width: $mobileBreakpoint) {
      padding-bottom:0
    }

    .button {
      margin: 50px 75px;

      min-width: 260px;
      min-height: 285px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      font-weight: 400;

      h2 {
        margin: 1em 0 0.5em;
        font-weight: bold;
      }

      &:first-of-type {
        border-color: $dentist-color;
        h2 {
          color: $dentist-color;
        }
      }
      &:last-of-type {
        border-color: $sleep-doctor-color;
        h2 {
          color: $sleep-doctor-color;
        }
      }

      @media (max-width: $mobileBreakpoint) {
        width: 26vw;
        min-width: auto;
        max-width: 300px;
        height:calc(80vh - 50px);
        min-height: auto;
        max-height: 200px;
        margin: calc(25vh - 70px) 20px;

        img {
          width: 60%;
          margin: -10px auto;
        }

        h2 {
          font-size:3vw
        }

        div {
          font-size:1.8vw
        }
      }
    }
  }

  &-end {
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;

    .button {
      width: 100%;
      max-width: 200px;
      margin: 0 20px;
      padding: 18px 15px 15px 15px;
      font-weight: 400;
    }

    @media (max-width: $mobileBreakpoint) {
      display:none
    }
  }
}

.browse {

  height: 100%;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
  padding: 20px;
  z-index: 1;

  @media (max-width: $mobileBreakpoint) {
    padding: 0;
    overflow: scroll;
  }

  > div:before {
    background: rgba(#AFAFAF, 0.65);
    z-index: -1;
    content: ' ';
    filter: blur(20px);
    position: absolute;
    top: -20px;
    bottom: -20px;
    right: 0;
    left: 0;

    @media (max-width: $mobileBreakpoint) {
      background: none;
    }
  }

  > div {
    display: flex;
    overflow: hidden;
    max-width: 1450px;
    margin: 110px auto;
    @media (max-width: 1440px) {
        margin: 40px auto;
    }

    @media (max-width: $mobileBreakpoint) {
      margin: 10px auto;
      padding: 0 20px;
      background: rgba(#FFFFFF, 0.65);
    }
  }

  .browse-dentist {
    width: 66.6%;
    flex-grow: 2;

    @media (max-width: $mobileBreakpoint) {
      width: 50%;

      h2 {
        color: $dentist-color !important
      }
    }

    .browse-features {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr 1fr;
      grid-auto-flow: column;


      @media (max-width: $mobileBreakpoint) {
        display: flex;
        flex-direction: column;
      }
    }
    .browse-feature {
      .browse-title {
        flex: 1;
      }
      .browse-number {
        background-color: $dentist-color;
        color: $white;

        @media (max-width: $mobileBreakpoint) {
          background-color: transparent;
          color: $dentist-color;
          border: 1px solid $dentist-color;
        }
      }
      @media (max-width: $mobileBreakpoint) {
        .browse-title {
          color: $dentist-color;
          font-size: 0.9em;
        }
      }
    }
  }
  .browse-sleep-doctor {
    width: 33.3%;

    @media (max-width: $mobileBreakpoint) {
      width: 50%;

      h2 {
        color: $sleep-doctor-color !important
      }
    }

    .browse-features {
      display: grid;
      grid-auto-rows: 1fr;
      grid-template-columns: 1fr;
      grid-auto-flow: row;
    }
    .browse-feature {
      .browse-title {
        flex: 1;
      }
      .browse-number {
        background-color: $sleep-doctor-color;
        color: $white;

        @media (max-width: $mobileBreakpoint) {
          background-color: transparent;
          color: $sleep-doctor-color;
          border: 1px solid $sleep-doctor-color;
        }
      }
      @media (max-width: $mobileBreakpoint) {
        .browse-title {
          color: $sleep-doctor-color;
          font-size: 0.9em;
        }
      }
    }
  }

  .browse-dentist, .browse-sleep-doctor {
    display: flex;
    flex-direction: column;

    h2 {
      text-align: center;
      color: $white;

      @media (max-width: $mobileBreakpoint) {
        text-align: left;
        font-weight: normal;
      }
    }

    .browse-feature {
      background: $white;
      margin: 5px;
      display: flex;
      border-radius: 25px;
      padding: 3px 5px;
      align-items: center;
      height: 50px;
      cursor: pointer;
      opacity: 1;
      transition: opacity 0.3s;
      &:hover {
        opacity: 0.7;
      }
      @media (max-width: $mobileBreakpoint) {
        background: transparent;
      }

      .browse-number {
        width: 45px;
        height: 45px;
        border-radius: 22.5px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 15px;
      }
    }
    .browse-features {

    }
  }
}
