$white: #FFFFFF;
$grey: #5D5D5D;
$red: #D90017;
$darker-red: #B6002E;
$dentist-color: #0080CF;
$darker-blue: #004897;
$purple: #691568;
$black: #2E2E2D;
$sleep-doctor-color: #ED6161;
$not-realy-white: #EFECEC;
