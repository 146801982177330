@import "../colors";

.doRotateScreen {

  &-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    background-image: url("../fond_narval_both.jpg");
    background-position: center;
    background-size: cover;
    z-index: 100;
  }

  &-block {
    border: solid 1px $sleep-doctor-color;
    border-radius: 10px;
    padding: 15px;
    background-color: rgba(#ffffff, 0.5);
    width:250px;
    height:250px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
      color:$sleep-doctor-color;
      margin-top:20px;
      font-size: 18px;
    }
  }
}
