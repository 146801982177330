.button {
  border: solid 1px #5d5d5d;
  border-radius: 10px;
  padding: 15px;
  background-color: rgba(#ffffff, 0.5);
  text-align: center;
  opacity: 1;
  transition: opacity 0.3s;
  cursor: pointer;
  font-weight: 600;

  h1, h2, h3 {
    margin: 0.1em 0;
  }

  &:hover {
    opacity: 0.7;
  }
}
