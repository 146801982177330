@import "../colors";
@import "../variables";
@import "../fonts/stylesheet.css";

.cookie-notice {
    &-content {
        font-family: 'Cern', 'Univers LT Std', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        width: 90%;
        max-width: 1200px;
        margin: auto;
        margin-top: 70px;
        height: calc(100% - 95px - 70px);
        overflow-y: scroll;
        font-size: 16px;

        h1, h3 {
            text-align: center;
        }
    }

    &-link {
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        display: inline;
        margin: 0;
        padding: 0;
        font-family: 'Cern', 'Univers LT Std', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
        font-size: 16px;
    }

    &-link:hover, &-link:focus {
        text-decoration: none;
    }
}