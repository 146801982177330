@import "../colors";
@import "../variables";

.training {
  display: grid;
  grid-template-columns: 400px 1fr;
  grid-template-rows: 100%;
  max-width: 1450px;
  margin: 0 auto;
  height: 100%;

  @media (max-width: $mobileBreakpoint) {
    grid-template-columns: 1fr;
  }

  &-column {
    position: relative;
    width: 400px;
    height: 100%;
    box-sizing: border-box;
    padding: 20px;
    margin: 0 -20px 0 20px;
    overflow: hidden;
    z-index: 1;

    @media (max-width: $mobileBreakpoint) {
      display: none;
    }

    &:before {
      background: rgba(#FFF, 0.65);
      z-index: -1;
      content: ' ';
      filter: blur(20px);
      position: absolute;
      top: -20px;
      bottom: -20px;
      right: 0;
      left: 0;
    }

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .training-steps {
      flex: 1 1 auto;
      overflow-y: auto;
      height: 0px;

      .training-link {
        display: grid;
        grid-template-columns: 30px 1fr;
        align-items: center;
        margin-top: 15px;
        margin-bottom: 15px;
        cursor: pointer;

        &-number {
          width: 30px;
          height: 30px;
          border-radius: 15px;
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px $grey;
          margin-right: 15px;
          background: transparent;
          color: $grey;
          transition: background-color 0.3s, color 0.3s;
        }
        &-text {
          padding-left: 10px;
        }

        &_isCurrent, &:hover {
          .training-link-number {
            color: $white;
            background: $grey;
            font-weight: 600;
          }
        }

        &_isCurrent {
          font-weight: 600;
        }
      }
    }

    .training-others {
      margin-top: 15px;
      font-weight: 600;
    }
  }
}

.app_editing {
    .training {
        &-column {
            .training-steps {
                .training-link {
                    &:hover {
                        .training-link-number:hover {
                            color: transparent;
                            background-image: url("./trash.svg");
                            background-size: 75%;
                            background-repeat: no-repeat;
                            background-position: 39% 38%;
                        }
                    }
                }
            }
        }
    }
}

.training-main {
  margin-left: 40px;
  padding-top: 33px;

  @media (max-width: $mobileBreakpoint) {
    margin-right: 30px;
    margin-left: 30px;
    padding-top: 0;

    .button {
        border-radius: 10px 10px 0 0;
        font-weight: normal;
        font-size: 14px;
        border-bottom: 0;
        padding: 15px 10px 5px;
        border-bottom: 0;
        overflow: hidden;
        white-space: nowrap;
      }
  }

  .megadraft-editor .DraftEditor-root, .megadraft-editor .toolbar {
    font-size: unset;
    line-height: unset;
    font-family: unset;
    letter-spacing: unset;
  }

  .training-main-tab {
    height: calc(100% - 85px);
    width: calc(100% - 85px);
    position: relative;

    @media (max-width: $mobileBreakpoint) {
      width: 100%;
      height: 100%;
    }

    .training-main-content {
      height: 100%;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: $white;
      border-radius: 10px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow-y: scroll;
      overflow-x: hidden;

      @media (max-width: $mobileBreakpoint) {
        > div {
          margin-top: 200px;
        }
      }

      .training-main-image {
        max-width: 100%;
        max-height: 100%;
        width: 100%;
      }

      .training-point {
        position: absolute;
        z-index: 2;
        $size: 40px;
        cursor: pointer;
        width: $size;
        height: $size;

        @media (max-width: $mobileBreakpoint) {
          transform: scale(0.8);
        }

        .training-point-num {
          position: absolute;
          bottom: 0;
          left: 0;
          top: 0;
          right: 0;
          z-index: 4;
          background: $white;
          border-radius: 50%;
          width: $size;
          height: $size;
          border: solid 1px $grey;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            transform: rotate(-45deg);
            position: absolute;
            transition: opacity 0.3s;
            opacity: 0;
          }

          span {
              position: absolute;
              transition: opacity 0.3s;
              opacity: 0;
          }

          .training-point-visible {
              opacity: 1;
          }
        }

        .training-point-bubble {
          position: absolute;
          z-index: 3;
          opacity: 0;
          background: $white;
          $size: 150px;
          width: $size;
          height: $size;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: solid 1px $grey;
          transition: opacity 0.3s;
          white-space: normal;

          .text {
            padding: 30% 15%;
            text-align: center;
            p {
              display: inline-block;
              margin: 0;
              padding: 0;
              margin-block-start: 0;
              margin-block-end: 0;
              margin-inline-start: 0;
              margin-inline-end: 0;
            }
            blockquote {
              font-size: 0.7em;
              display: inline-block;
              margin: 0;
              padding: 0;
              margin-block-start: 0;
              margin-block-end: 0;
              margin-inline-start: 0;
              margin-inline-end: 0;
              border: none;
              color: unset;
            }
          }

          //.text {
          //  height: 100%;
          //  width: 100%;
          //  position: relative;
          //
          //  &::before {
          //    content: "";
          //    width: 50%;
          //    height: 100%;
          //    float: left;
          //    shape-outside: polygon(
          //                    0 0,
          //                    98% 0,
          //                    50% 6%,
          //                    23.4% 17.3%,
          //                    6% 32.6%,
          //                    0 50%,
          //                    6% 65.6%,
          //                    23.4% 82.7%,
          //                    50% 94%,
          //                    98% 100%,
          //                    0 100%
          //    );
          //    shape-margin: 10%;
          //  }
          //  & > p {
          //    height: 100%;
          //    width: 100%;
          //    position: absolute;
          //    top: 0;
          //    text-align: center;
          //  }
          //  & > p::after {
          //    content: "";
          //    width: 50%;
          //    height: 100%;
          //    float: right;
          //    shape-outside: polygon(
          //                    2% 0%,
          //                    100% 0%,
          //                    100% 100%,
          //                    2% 100%,
          //                    50% 94%,
          //                    76.6% 82.7%,
          //                    94% 65.6%,
          //                    100% 50%,
          //                    94% 32.6%,
          //                    76.6% 17.3%,
          //                    50% 6%
          //    );
          //    shape-margin: 10%;
          //  }
          //}

          &_pos_tr {
            bottom: 0;
            left: 0;
          }

          &_pos_tl {
            bottom: 0;
            right: 0;
          }

          &_pos_br {
            top: 0;
            left: 0;
          }

          &_pos_bl {
            top: 0;
            right: 0;
          }
        }

        @media (min-width: $mobileBreakpoint) {
            &:hover {
            .training-point-bubble {
                opacity: 1;
            }
            }
        }

        &_open {
          .training-point-bubble {
            opacity: 1;
          }
        }
      }
    }

    .training-editor-buttons {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      display: flex;
      width: 100%;
      justify-content: space-between;
      z-index: 2;
      margin-right: 50px;
      margin-left: 50px;

    }

    .training-main-buttons {
      position: absolute;
      bottom: 0;
      transform: translateY(50%);
      display: flex;
      width: 100%;
      justify-content: space-between;

      @media (max-width: $mobileBreakpoint) {
        transform: translateY(0);
        z-index: 2;
      }

      .training-button-next {
        min-width: 150px;
        margin-right: 50px;

        @media (max-width: $mobileBreakpoint) {
          min-width: 100px;
          margin-right: 20px;
        }
      }

      .training-button-steps {
        display: flex;
        margin-left: 50px;

        @media (max-width: $mobileBreakpoint) {
          margin-left: 20px;
        }

        .training-button-step {
          margin-right: 15px;

          &_notCurrent {

            background: rgba(255, 255, 255, 0.5);
            color: $black;
            border-color: $black;
          }
        }
      }
    }
  }
}

.app-main_kind_sleep-doctor {

  .training-main {
    .training-main-tab {

      .button {
        background: $sleep-doctor-color;
        color: $white;
        border-color: $sleep-doctor-color;
      }

      .training-main-content {
        .training-point {
          .training-point-num {
            background: $sleep-doctor-color;
            color: $white;
            border-color: $sleep-doctor-color;
          }

          .training-point-bubble {
            border-color: $sleep-doctor-color;
            color: $sleep-doctor-color !important;
          }
        }
      }
    }
  }
}

.app-main_kind_dentist {
  .training-main {
    .training-main-tab {

      .button {
        background: $dentist-color;
        color: $white;
        border-color: $dentist-color;
      }

      .training-main-content {
        .training-point {
          .training-point-num {
            background: $dentist-color;
            color: $white;
            border-color: $dentist-color;
          }

          .training-point-bubble {
            border-color: $dentist-color;
            color: $dentist-color !important;
          }
        }
      }
    }
  }
}

.app-main {
  .megadraft-editor .megadraft-block {
    padding-bottom: unset;
  }

  .megadraft-editor .paragraph + .paragraph {
    margin-top: unset;
  }

  .megadraft-editor .DraftEditor-root, .megadraft-editor .toolbar {
    color: unset;
  }
}

.app_editing {
  .training-link-number:hover {
    content: 'X';
    background-color: $darker-red;
  }
}
