@import "../colors";
@import "../variables";
@import "../fonts/stylesheet.css";

.footer {
    text-align: center;
    position: fixed;
    bottom: 15px;
    left: 0;
    right: 0;
    @media (max-width: $mobileBreakpoint) {
        position: absolute;
        transform: rotate(270deg);
        right: -27px;
        left: auto;
        bottom: auto;
        top: 50%;
    }
    &-link {
        background-color: transparent;
        border: none;
        cursor: pointer;
        text-decoration: underline;
        display: inline;
        margin: 0;
        padding: 0;
    }

    &-link:hover, &-link:focus {
        text-decoration: none;
    }
}