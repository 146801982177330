@import "colors";
@import "variables";
@import "./fonts/stylesheet.css";

body {
  margin: 0;
  font-family: 'Univers LT Std', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.app {
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  width: 100%;
  display: grid;
  grid-template-rows: 95px 1fr;
  grid-template-columns: 100%;

  @media (max-width: $mobileBreakpoint) {
    grid-template-rows: 50px 1fr;
  }

  &-edit-mode {
    position: absolute;
    top: 5px;
    left: 5px;
  }

  &-header {
    height: 95px;
    display: flex;
    padding: 20px 100px;
    max-width: 1450px;
    width: 100%;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;

    img {
      display: inline-block;
      &:first-of-type {
        cursor: pointer;
      }
    }

    @media (max-width: $mobileBreakpoint) {
      padding: 10px 20px;
      height: 50px;

      img {
        height: 30px;
      }
    }
  }

  &-headerTitle {
    font-size: 0.85em;
    color: $grey;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-right: 20px;

    .app-sleep-doctor {
      color: $sleep-doctor-color;
    }
    .app-dentist {
      color: $dentist-color;
    }
  }

  &-mobile-right-side {
    display: flex;
    flex-direction: row;
    align-items: center;

    > img:first-child {
      height: 12px;
      margin-right: 10px;
    }
  }

  &-main {
    border-top: solid 1px black;
    background-image: url("fond_narval_both.jpg");
    background-position: center;
    background-size: cover;

    &_kind_sleep-doctor {
      background-image: url("fond_Narval_easy_alt2.jpg");
    }
    &_kind_dentist {
      background-image: url("fond_Narval_easy_alt3.jpg");
    }
  }
}

.app-save-button {
  position: fixed;
  top: 5px;
  right: 5px;
}

.route-wrapper {
  height: 100%;
  width: 100%;
  position: relative;
  > div {
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
